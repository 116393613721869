import React from "react"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import Reveal from "react-reveal/Reveal"
import SEO from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons"

const features = [
  "Create unlimited webhooks",
  "Connect unlimited webhook accounts",
  "Full use of command line interface",
  "API access",
  "Access to all integrations",
  "Support chat",
  "Add unlimited users",
  "2 week free trial",
  "Cancel anytime",
]

const PricingPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          urls {
            register
          }
        }
      }
    }
  `)

  return (
    <Layout navClass="w_menu" headerClass="transparent_header">
      <SEO title="Pricing" />
      <section className="breadcrumb_area">
        <img
          className="breadcrumb_shap"
          src={require("../images/breadcrumb/banner_bg.png")}
          alt=""
        />
        <div className="container">
          <div className="breadcrumb_content text-center">
            <Reveal effect="fadeInUp">
              <h1 className="f_p f_700 f_size_50 w_color l_height50 mb_20">
                Pricing
              </h1>
            </Reveal>
          </div>
        </div>
      </section>
      <section className="pricing_area_four sec_pad pt-4">
        <div className="container">
          <div className="hosting_title security_title text-center">
            <h2 className="wow fadeInUp" data-wow-delay="0.2s">
              <span>Simple, unlimited webhook management, $29 per month</span>2
              week free trial included
            </h2>
          </div>
          <div className="price_info_two price_info_three">
            <div className="price_head">
              <div className="p_head time">
                <h4>Webhook management</h4>
              </div>
              <div className="p_head">
                <h5>Unlimited</h5>
                <p>$29 / mo</p>
              </div>
            </div>
            <div className="price_body">
              {features.map((feature, i) => (
                <div className="pr_list" key={i}>
                  <div className="price_item">
                    <h5>{feature}</h5>
                  </div>
                  <div className="price_item" data-title="Unlimited plan">
                    <h5>
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </h5>
                  </div>
                </div>
              ))}
              <div className="pr_list">
                <div className="price_item"></div>
                <div className="price_item">
                  <a
                    href={data.site.siteMetadata.urls.register}
                    className="price_btn btn_hover"
                  >
                    Start free trial
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="d-block d-sm-block d-md-none text-center mt-5">
            <a
              href={data.site.siteMetadata.urls.register}
              className="seo_btn seo_btn_one btn_hover"
            >
              Start free trial
            </a>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PricingPage
